.section__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section__container h2 {
    font-size: 60px;
    font-weight: 200;
    margin-bottom: 15px;
}

.section-p {
    line-height: 1.6;
    font-size: 25px;
    max-width: 800px;
    text-align: center;
}

#button-right{
    display: flex;
    flex-direction: row;
}