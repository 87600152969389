.project-card__container {
    display: flex;
    flex: 1;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    margin: 20px ; 
    color: white;
    flex: 1;
    height: 300px;
    min-width: 350px;
    background-size: 700px;
}

.project-card__text {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    opacity: 0;
}

.project-card__button {
    border: 2px solid white;
    background: transparent;
    height: 50px;
    color: white;
    width: 40%;
}

.project-card__container:hover .project-card__text {
    transition: 0.5s ease;
    opacity: 1;
    display: flex;
}
