.prompt__container {
    display: flex;
    flex-direction: column;
    align-self: center;
    color: white;
    align-items: center;
    max-width: 700px;
    position: absolute;
    margin-top: 400px;
    text-align: center;
}

h5 {
    font-style: italic;
}

h1 {
    font-size: 70px;
}

.prompt__container button {
    background: transparent;
    margin: 20px 20px;
    border: 2px solid #fff;
    width: 120px;
    height: 50px;
    color: white;
}