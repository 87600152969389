.footer__container {
    color: #fff;
    background-color: rgba(26,26,26, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.social__container {
    display: flex;
}

.social__container a {
    margin: 0px 10px 0px 10px;
}

p {
    font-size: 11px;
}