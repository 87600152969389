html {
    scroll-behavior: smooth;
}

.main__container {
    display: flex;
    flex-direction: column;
    font-family: Arial,Helvetica,sans-serif;
    line-height: 1.6em;
    font-family: 'Monsterrat';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 1.8em;
    text-transform: none;
    color: rgba(26,26,26,.75);
}

.video__wrapper {
    height: 785px;
    overflow: hidden;
}

video {
    min-width: 100%; 
    filter: brightness(0.70) contrast(1.05);
}
.content__container {
    margin: 50px 40px;
}

.about-text {
    display: flex;
    flex-direction: column;
    color: #212529;
}

.about-text p {
    font-size: 1.6rem;
    font-weight: 100;
    line-height: 1.6;
    margin-bottom: 50px;
    letter-spacing: 0.016;
    font-family: 'Taviraj', serif;
}

.rh-logo {
    height: 60px;
    top: 30px;
    /* margin:  0 0 0 70px; */
    position: absolute;
}

.backupimg {
    height: 900px;
    background-image: url('../assets/tokyoHotel.jpg');
    background-position: center;
}

@media screen and (min-width: 500px) {
    .content__container {
        margin: 100px 150px;
    }

    .rh-logo {
        margin:  0 0 0 70px;
        position: fixed;
    }
}