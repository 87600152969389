.header-des__container {
    display: flex;
    flex-direction: column;
    align-self: center;
    color: white;
    align-items: center;
    max-width: 700px;
    position: absolute;
    margin-top: 400px;
    text-align: center;
}

h1 {
    font-size: 70px;
}