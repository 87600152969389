.main-button__container{
    display: flex;
    height: 480px;
}

.main-button {
    height: 100%;
    width: 100px;
    font-size: 25px;
    border-left: 4px solid white; 
    background: transparent;
}