.project-section__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

h2 {
    font-size: 40px;
}

.project-section__button {
    height: 75px;
    width: 300px;
    font-size: 25px;
    border: 2px solid black;
    background: transparent;
}

.section__container {
    width: 100%;
}

@media screen and (min-width: 883px) {
    .card__wrapper {
        margin: 20px 50px;
    }
}